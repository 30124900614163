//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    dataLoading: {
      type: [Boolean],
      required: true,
      default: false
    },
    moduleType: {
      type: [String],
      required: true,
      default: "customer"
    },
    allkey: {
      type: [String],
      required: true,
      default: "all_customers"
    },
    countkey: {
      type: [String],
      required: true,
      default: "customer_status_count"
    },
    statusList: {
      type: [Array, Object],
      required: true,
      default: function _default() {
        return new Array();
      }
    },
    extraList: {
      type: [Array],
      default: function _default() {
        return new Array();
      }
    }
  },
  data: function data() {
    return {
      dataLoadingList: [{
        headercolor: "primary",
        bgcolor: "bg-light-warning"
      }, {
        headercolor: "primary",
        bgcolor: "bg-light-success"
      }, {
        headercolor: "primary",
        bgcolor: "bg-light-info"
      }]
    };
  }
};