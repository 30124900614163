//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    customClass: {
      type: String,
      default: null
    }
  }
};