import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { SearchEventBus } from "@/core/lib/search.lib";
export default {
  props: {
    dataLoading: {
      type: Boolean
    },
    pageModule: {
      required: true,
      type: String,
      default: function _default() {
        return "";
      }
    },
    basicSearchFields: {
      type: Array,
      required: true,
      default: function _default() {
        return new Array();
      }
    },
    allowedSearchFields: {
      type: Array,
      required: true,
      default: function _default() {
        return new Array();
      }
    }
  },
  data: function data() {
    return {
      searchParam: null,
      listingSearch: new Object()
    };
  },
  watch: {
    "$route.query": {
      deep: true,
      immediate: true,
      handler: function handler() {
        this.updateFields();
      }
    }
  },
  methods: {
    getFilterLabel: function getFilterLabel(field) {
      var child = [];
      var levelOne = field.split(".");
      var parent = levelOne[0].split("_").join(" ");

      if (levelOne[1]) {
        child.push(levelOne[1].split("_").join(" "));
      }

      if (child.length) {
        var childText = child.join(" ");
        return parent + "[" + childText + "]";
      }

      if (this.pageModule == "Invoice") {
        parent = this.lodash.replace(parent, "title", "invoice subject").replaceAll("title", "invoice subject");
      }

      parent = this.lodash.replace(parent, "zip_code", "postal code").replaceAll("zip_code", "postal code").replaceAll("zip code", "postal code");
      parent = this.lodash.lowerCase(parent);
      var result = this.lodash.replace(parent, "text", "name").replaceAll("[barcode]", " #").replaceAll("barcode", "".concat(this.pageModule, " #"));
      return result;
    },
    clearSearch: function clearSearch() {
      SearchEventBus.$emit("clear-search", true);
    },
    advancedSearch: function advancedSearch() {
      SearchEventBus.$emit("advanced-search", true);
    },
    updateFields: function updateFields() {
      var _this = this;

      _this.searchParam = null;
      _this.listingSearch = new Object();

      if (_this.lodash.isEmpty(_this.$route.query) === false && _this.$route.query.search) {
        _this.searchParam = _this.$route.query.search;
      }

      if (_this.lodash.isEmpty(_this.$route.query) === false && !_this.$route.query.search) {
        for (var key in _this.$route.query) {
          if (_this.lodash.includes(_this.allowedSearchFields, key)) {
            if (_this.lodash.isString(_this.$route.query[key])) {
              _this.listingSearch[key] = _this.$route.query[key];
            } else if (_this.lodash.isEmpty(_this.$route.query[key]) === false) {
              _this.listingSearch[key] = _this.$route.query[key];
            }
          }
        }
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.listingSearch = new Object();
    this.searchParam = null;
  }
};