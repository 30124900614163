import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import KTPageTipsPlugin from "@/assets/js/layout/extended/page-tips.js";
import { QUERY } from "@/core/services/store/request.module";
export default {
  name: "KTPageTips",
  data: function data() {
    return {
      isFocused: false,
      pageLoader: true,
      isMounted: false,
      searchModel: null,
      pageTips: []
    };
  },
  props: {
    module: {
      type: String,
      required: true
    }
  },
  methods: {
    onEsc: function onEsc() {
      this.resetSearch();
    },
    onEnter: function onEnter() {
      this.startSearch();
    },
    onTab: function onTab() {
      this.startSearch();
    },
    startSearch: function startSearch() {
      var _this = this;

      _this.pageLoader = true;

      _this.$store.dispatch(QUERY, {
        url: "page-tips/" + _this.module,
        data: {
          search: _this.searchModel
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this.pageTips = data;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoader = false;
      });
    },
    resetSearch: function resetSearch() {
      var _this = this;

      _this.$nextTick(function () {
        _this.searchModel = null;
        _this.isFocused = false;
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.isMounted = false;
  },
  mounted: function mounted() {
    var _this = this;

    _this.startSearch();

    KTPageTipsPlugin.init(_this.$refs[_this.pageTipId], _this.pageTipId);
    _this.isMounted = true;
  },
  computed: {
    pageTipId: function pageTipId() {
      return "kt_".concat(this.module, "_page_tips");
    },
    isSearching: function isSearching() {
      return this.searchModel && this.searchModel.length > 0;
    }
  }
};